/* eslint @typescript-eslint/no-var-requires: "off" */
import { FC, useEffect, useState } from "react";
import { Box } from "@twilio-paste/core/box";
import { RaceConnect } from "./components/RaceConnect/WidgetLoader";


export const DemoApp: FC = () => {

	const [config, setConfig] = useState<any>({});
	const axios = require("axios");


	useEffect(() => {
		let lang = "English"

		if(window.location.href.split("/").includes("es"))
		{
			lang = "Spanish"
		}
		console.log(window.location.href, lang)

		const url = "https://chat-widget-5710.twil.io/get-config"
		const s = window.location.href.split("/")
		let loc = ""

		if(s.indexOf("es") >=0)
		{
			s.splice(s.indexOf("es"), 1)

			loc = s.join("/")
		}
		else
		{
			loc = window.location.href
		}

		console.log("loc", loc, s.indexOf("es"), s)

		const data = JSON.stringify({
			"location": loc,
			"useProd": false
		});

		const config = {
			method: "post",
			url,
			headers: {
				"Content-Type": "application/json"
			},
			data: data
		};

		axios(config)
			.then((response: any) => {

				const c = { ...response.data, language : lang }

				let s = []

				// if showQ1 is true, remove CC and Budget from list
				if(c.showQ1)
				{
					s = response.data.services.filter((x: { key: string; }) => x.key !== "Credit Counseling" && x.key !== "Overall Budget and Financial Review")
					console.log(s)
					c.otherServices = s
				}
				else
				{
					c.otherServices = response.data.services
				}


				console.log("c", c)
				setConfig(c)

			})
			.catch((error: any) => {
				console.error(error.message)
			})



	}, []);

	return (
		<Box id='main' >
			{config && <RaceConnect  conf = {config} />}
		</Box>
	)
}