/* eslint @typescript-eslint/no-var-requires: "off" */
import * as React from "react";
import { AxiosRequestConfig } from "axios";
import { FC, ChangeEvent, useEffect, useRef } from "react";
import { CallContainer } from "../../../assets/index.styles";
import { Select, Option } from "@twilio-paste/core/select";
import { Input } from "@twilio-paste/core/input";
import { Button } from "@twilio-paste/core/button";
import { Box } from "@twilio-paste/core/box";
import { Stack } from "@twilio-paste/core/stack";
import { HelpText } from "@twilio-paste/core/help-text";
import { Label } from "@twilio-paste/core/label";
import {
	ChatLog,
	ChatMessage,
	ChatMessageMeta,
	ChatMessageMetaItem,
	ChatBubble,
	ChatBookend,
	ChatBookendItem
} from "@twilio-paste/core/chat-log";
import { Checkbox } from "@twilio-paste/core/checkbox";
import { InlineWidget } from "react-calendly";
import FadeIn from "react-fade-in";
import { EditIcon } from "@twilio-paste/icons/esm/EditIcon";
import { Spinner } from "@twilio-paste/core/spinner";
import { useVideoContext } from "src/hooks/useVideoContext";
import { Text } from "@twilio-paste/core/text";
import { MicrophoneOffIcon } from "@twilio-paste/icons/esm/MicrophoneOffIcon";
import { MicrophoneOnIcon } from "@twilio-paste/icons/esm/MicrophoneOnIcon";
import { CallFailedIcon } from "@twilio-paste/icons/esm/CallFailedIcon";
import { useVoiceContext } from "src/hooks/useVoiceContext";
import useSound from "use-sound";
import dial from "../../../assets/dial.wav";
import useStateRef from "react-usestateref";
import {
	MediaPermissionsError,
	MediaPermissionsErrorType,
	requestMediaPermissions
} from "mic-check";

/*
TODO:


- resize video - SHAW
- make video responsive and work with multiple participants - SHAW
- resize window needs to be a bit nicer, if smol, med then 100vw, else 70vw



*/

interface IProps {
	conf: any;
	setShowWidget: (showWidget: boolean) => void;
}

export const Locator: FC<IProps> = ({ conf, setShowWidget }) => {

	const [locatorDetails, setLocatorDetails] = React.useState<any>("");
	const [step, setStep] = React.useState(0);
	const [stepZeroSelection, setStepZeroSelection] = React.useState("");
	const [consent, setConsent] = React.useState(false);
	const bookingWidgetDivRef = useRef<HTMLDivElement>(null);
	const endRef = useRef<HTMLDivElement>(null);
	const [startTime, setStartTime] = React.useState("");
	const [availability, setAvailability] = React.useState<any>();
	const [connectionOptions, setConnectionOptions] = React.useState<any[]>();
	const [connectionOption, setConnectionOption, connectionOptionRef] = useStateRef<string>();
	const [showLoading, setShowLoading] = React.useState<boolean>(false);


	// video bits
	const { video, isMuted, localVideoMedia, remoteVideoMedia, connect, disconnect, ended, attended } = useVideoContext();
	const { init: videoInit } = useVideoContext();
	const { connect: voiceConnect, voice, disconnect: voiceDisconnect, status: voiceStatus, isMuted: voiceIsMuted, init: voiceInit } = useVoiceContext();
	const [isVideo, setIsVideo] = React.useState<boolean>(false);
	const [videoLoading, setVideoLoading] = React.useState<boolean>(false);
	const [hideSpinner, setHideSpinner] = React.useState<boolean>(false);

	const [zipError, setZipError] = React.useState("");
	const [phoneError, setPhoneError] = React.useState("");
	const [emailError, setEmailError] = React.useState("");

	const [locatorResults, setLocatorResults] = React.useState<any>("");

	const [back, setBack] = React.useState<any>("back");
	const [next, setNext] = React.useState<any>("Next");
	const [yes, setYes] = React.useState<any>("Yes");
	const [no, setNo] = React.useState<any>("No");
	const [you, setYou] = React.useState<any>("You");
	const [chatMessageAriaPenny, setchatMessageAriaPenny] = React.useState<any>("said by Penny");
	const [chatMessageAriaSelf, setchatMessageAriaSelf] = React.useState<any>("said by you");
	const [locatorError, setLocatorError] = React.useState("Sorry! It looks like we are experiencing some technical difficulties with this app. You can still get connected to an agency for financial help by submitting a form or calling one of our toll free numbers on nfcc.org");

	const [isSingle, setIsSingle] = React.useState(false);
	const [singleContinue, setSingleContinue] = React.useState(false);
	const [domain, setDomain] = React.useState("")

	const [newConnectionAgent, setNewConnectionAgent, newConnectionAgentRef] = useStateRef<any>({});
	const [agencyServiceConfigId, setAgencyServiceConfigId, agencyServiceConfigIdRef] = useStateRef(0);
	const [calendlyCompleted, setCalendlyCompleted] = React.useState<boolean>(false);
	const [ending, setEnding] = React.useState<boolean>(false)

	// device management
	const [audioDevicesInput, setAudioDevicesInput] = React.useState<any>([]);
	const [audioDevicesOutput, setAudioDevicesOutput] = React.useState<any>([]);
	const [videoDevicesInput, setVideoDevicesInput] = React.useState<any>([]);
	const [audioDeviceInput, setAudioDeviceInput] = React.useState<any>();
	const [audioDeviceOutput, setAudioDeviceOutput] = React.useState<any>();
	const [videoDeviceInput, setVideoDeviceInput] = React.useState<any>();
	const [permissionsError, setPermissionsError] = React.useState<any>({});
	const [hasAllPermissions, setHasAllPermissions] = React.useState(false);
	const [checkComplete, setCheckComplete] = React.useState(false);

	// voice bits
	// const [playBeeps, { stop }] = useSound(beeps);
	const [playDial] = useSound(dial);
	// const [ringing, setRinging, ringingRef] = useState(false)


	const axios = require("axios");


	const handleDisconnect = async () => {
		// if the call is ended while in the queue, need to remove the task from flex
		setEnding(true);

		if (!attended) {


			// get task by videoChatRoomSid attribute
			const { agencyVideoId } = locatorResults

			if (video) {
				if (video.room) {
					const roomSid = video?.room.sid

					const url = "https://video-campaigns-6267.twil.io/" + domain + "/cancel-task"

					// cancel task
					const config: AxiosRequestConfig = {
						method: "post",
						maxBodyLength: Infinity,
						url,
						headers: {
							"Content-Type": "application/json"
						},
						data: JSON.stringify({
							useProd: conf.useProd,
							data: JSON.stringify({ "agencyVideoId": agencyVideoId, "videoChatRoomSid": roomSid })
						})
					};
					try {
						const response = await axios.request(config);
						disconnect()
					}
					catch (error) {
						disconnect()
					}
				}
			}

		}
		else {
			disconnect()
		}

	}

	// handle translation
	const translate = (en: string, es: string) => {
		if (conf.language == "Spanish") {
			return es
		}
		return en
	}

	useEffect(() => {
		if (voiceStatus) {
			jumpToEnd()
		}

	}, [voiceStatus]);

	function isCalendlyEvent(e: any) {
		return e.data.event && e.data.event.indexOf("calendly") === 0;
	}

	useEffect(() => {
		const d = new Date();
		let mins: string = d.getMinutes().toString()

		if (mins.toString().length == 1) {
			mins = "0" + mins.toString()
		}

		setStartTime(d.getHours() + ":" + mins)

		let dom = ""


		if (["http://localhost:3000"].includes(document.location.origin)) {
			dom = "localhost"

		}
		else if (["https://nfcc-video-app-build.pages.dev"].includes(document.location.origin)) {
			dom = "pages.dev"
		}
		else if (["https://nfcc.org"].includes(document.location.origin)) {
			dom = "video.nfcc.org"
		}
		setDomain(dom)


	}, []);

	useEffect(() => {
		try {


			if (Object.keys(conf).length > 0) {

				requestMediaPermissions({ video: true, audio: true }).then(() => {

					navigator.mediaDevices
						.getUserMedia({ video: true, audio: true })

					// get devices
					navigator.mediaDevices
						.enumerateDevices()
						.then((devices) => {

							const ai: any[] = []
							const ao: any[] = []
							const vi: any[] = []

							const ps = [];

							ps.push(
								new Promise((resolve, reject) => {
									try {
										devices.forEach((device) => {

											console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
											if (device.kind === "audioinput") {
												ai.push(device)
												if (device.deviceId === "default") {
													setAudioDeviceInput(device)
													localStorage.setItem("input_device", JSON.stringify(device));
												}
											}
											else if (device.kind === "audiooutput") {
												ao.push(device)
												if (device.deviceId === "default") {
													setAudioDeviceOutput(device)
													localStorage.setItem("speaker_device", JSON.stringify(device));
												}
											}
											else if (device.kind === "videoinput") {
												vi.push(device)
												if (device.deviceId === "default") {
													setVideoDeviceInput(device)
													localStorage.setItem("video_device", JSON.stringify(device));
												}
											}
										}
										);
										resolve("done")
									}
									catch (err) {
										console.error(err)
										reject("")
									}
								}))

							Promise.all(ps).then((i) => {
								// console.log(ai,ao,vi)
								setAudioDevicesInput(ai)
								setAudioDevicesOutput(ao)
								setVideoDevicesInput(vi)
							})
						})
						.catch((err) => {
							console.error(`${err.name}: ${err.message}`);
						});
				})

				if (!conf.showQ1) {
					setStep(2);
				}
				if (conf.language === "Spanish") {
					// init strings
					setBack("atrás")
					setchatMessageAriaPenny("dicho por Penny")
					setchatMessageAriaSelf("dicho por ti")
					setNext("próximo")
					setYou("Tú")
					setYes("Si")
					setNo("No")
					setLocatorError("¡Lo siento! Parece que estamos experimentando algunas dificultades técnicas con esta aplicación. Todavía puede conectarse con una agencia para obtener ayuda financiera enviando un formulario o llamando a uno de nuestros números gratuitos en nfcc.org")
				}

				if (conf.otherServices.length === 1 && !conf.showQ1) {
					const l = { ...locatorDetails };
					l.serviceName = conf.otherServices[0].key;
					setLocatorDetails(l);
					setIsSingle(true)
					setStep(3)
					console.log(conf.otherServices[0].key)
				}
				else {
					setSingleContinue(true)
				}
			}
		} catch (err) {
			console.error("init", err)
		}

	}, [conf]);


	const onChange = ({
		target
	}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {


		const l = { ...locatorDetails };
		l[target.id] = target.value;
		setLocatorDetails(l);
	};

	const onChangeIncrement = ({
		target
	}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

		const l = { ...locatorDetails };

		if (target.id == "serviceName") {
			if (target.value == "default") {
				return
			}
			else {
				l["serviceId"] = target.value.split("#")[1]
				l["serviceName"] = target.value.split("#")[0]
			}
		}
		else {
			l[target.id] = target.value
		}

		setLocatorDetails(l);
		setStep(step + 1);
	};


	const setDevice = ({
		target
	}: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		console.log(target.id)
		if (target.id === "audioDeviceInput") {
			setAudioDeviceInput(target.value)
			localStorage.setItem("input_device", target.value);
		}
		else if (target.id === "audioDeviceOutput") {
			setAudioDeviceOutput(target.value)
			localStorage.setItem("speaker_device", target.value);
			console.log("speaker_device", target.value)
		}
		else if (target.id === "videoDeviceInput") {
			setVideoDeviceInput(target.value)
			localStorage.setItem("video_device", target.value);
		}

	};


	const stepZeroOnChange = (target: string) => {

		setStepZeroSelection(target);
		if (target === "More Options") {
			setStep(2);
			if (conf.language === "Spanish") {
				setStepZeroSelection("Mas opciones")
			}
		} else {
			setStep(3);
			const l = { ...locatorDetails };
			if (target === "Credit Card Help") {
				l["serviceName"] = "Credit Counseling";
				l["serviceId"] = 1
				setStepZeroSelection(
					translate(conf.services.find((x: any) => x.key === "Credit Counseling").value, conf.services.find((x: any) => x.key === "Credit Counseling").spanishValue));

			} else {
				l["serviceName"] = "Overall Budget and Financial Review";
				l["serviceId"] = 2
				setStepZeroSelection(
					translate(conf.services.find((x: any) => x.key === "Overall Budget and Financial Review").value, conf.services.find((x: any) => x.key === "Overall Budget and Financial Review").spanishValue));

			}
			setLocatorDetails(l);
		}
	};

	const jumpToEnd = () => {

		setTimeout(() => {
			if (endRef.current) {
				endRef.current.scrollIntoView({ behavior: "smooth" });

			}
		}, 200)
	};

	const increment = () => {
		setStep(step + 1);

		jumpToEnd()
	};

	const cont = () => {
		setSingleContinue(true)

		jumpToEnd()
	};

	const close = () => {
		setShowWidget(true);
	};

	const setStepNumber = (s: number) => {

		if (s == 4) {
			const l = { ...locatorDetails };
			delete l.zipCode;
			delete l.emailAddress;
			delete l.phoneNumber;
			setLocatorDetails(l);
		}

		setStep(s);
		// jumpToEnd()
	};

	const startVideo = (l: any) => {
		if (!l) {
			l = locatorResults
		}

		// let label = locatorDetails.serviceName + "_" + locatorDetails.code + "!" + conf.videoCampaignId + "!" + Math.ceil(Math.random() * 100) + "_" + (conf.language == "Spanish" && "es" || "en")

		const label = (locatorDetails.serviceName === "Overall Budget and Financial Review" && "Budget" || locatorDetails.serviceName) + "_" + (l.code ?? "").replace("-", "*") + "!" + conf.videoCampaignId + "!" + Math.ceil(Math.random() * 100) + "_" + (conf.language == "Spanish" && "es" || "en")

		console.log("label", label)


		videoInit(
			l.accountSid,
			locatorDetails.firstName + " " + locatorDetails.lastName,
			l.kioskSid,
			label, JSON.parse(audioDeviceOutput).deviceId ?? null
		);





		setTimeout(() => {

			connect()
			setIsVideo(true)
			const dad = document.getElementById("dad1")

			if (dad) {
				if (dad.clientWidth > 600) {
					dad.style.minWidth = "70vw"
					dad.style.maxWidth = "70vw"
					dad.style.width = "70vw"
				}
				else {
					dad.style.minWidth = "95vw"
					dad.style.maxWidth = "95vw"
					dad.style.width = "95vw"
				}
			}

		}, 1000)
	}

	useEffect(() => {
		if (ended && isVideo) {
			const dad = document.getElementById("dad1")

			setTimeout(() => {
				if (dad) {
					if (dad.clientWidth > 450) {
						dad.style.minWidth = "40vw"
						dad.style.maxWidth = "40vw"
						dad.style.width = "40vw"
					}
				}

				jumpToEnd()
			}, 1000)
		}
	}, [ended])

	const validate = () => {
		let isError = false;

		// clear previous validation
		setZipError("")
		setEmailError("")
		setPhoneError("")

		// validation

		if (!Object.keys(locatorDetails).includes("zipCode")) {

			setZipError(translate("Please enter a valid ZIP", "Por favor ingrese un código postal válido"))

			isError = true;
		}
		else if (! /[0-9]{5}/.test(locatorDetails.zipCode)) {
			setZipError(translate("Please enter a valid ZIP", "Por favor ingrese un código postal válido"))

			isError = true;
		}

		if (!Object.keys(locatorDetails).includes("phoneNumber")) {

			setPhoneError(translate("Please enter a valid phone", "Por favor ingrese un número de teléfono válido"))

			isError = true;
		}
		// eslint-disable-next-line
		else if (! /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(locatorDetails.phoneNumber) || locatorDetails.phoneNumber.length == 1) {
			setPhoneError(translate("Please enter a valid phone", "Por favor ingrese un número de teléfono válido"))

			isError = true;
		}

		if (!Object.keys(locatorDetails).includes("emailAddress")) {

			setEmailError(translate("Please enter a valid email", "Por favor ingrese un correo electrónico válido"))

			isError = true;
		}
		// eslint-disable-next-line
		else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/.test(locatorDetails.emailAddress) || locatorDetails.emailAddress.length == 1) {
			setEmailError(translate("Please enter a valid email", "Por favor ingrese un correo electrónico válido"))

			isError = true;
		}

		return isError

	}



	const checkVideoAvailability = async () => {

		setShowLoading(true)

		const isError = validate()

		if (!isError) {

			const { videoCampaignId } = conf
			const l = { ...locatorDetails, videoCampaignId, service: locatorDetails.serviceName, email: locatorDetails.emailAddress, language: conf.language }

			if (videoCampaignId) {
				// check availability
				const config: AxiosRequestConfig = {
					method: "post",
					maxBodyLength: Infinity,
					url: "https://video-campaigns-6267.twil.io/" + domain + "/get-availability",
					headers: {
						"Content-Type": "application/json"
					},
					data: JSON.stringify({ useProd: conf.useProd, data: JSON.stringify(l) })
				};
				const response = await axios.request(config);
				setAvailability(response.data)

				let opt: any[] = []


				if (response.data.available) {
					// offer connection options: video (exp. wait time), c2c, other
					if(conf.connectionTypes.includes('Video'))
					{
						// get the lowest wait time
						const lowestWaitTimeMins = Math.ceil(response.data.results.reduce((prev: any, curr: any) => {
							return prev.availability.waitTimeSecs < curr.availability.waitTimeSecs ? prev : curr;
						}).availability.waitTimeSecs / 60);

						opt.push({ "name": translate("Video (exp. wait time: " + lowestWaitTimeMins + " mins)", "Video (tiempo de espera exp.: " + lowestWaitTimeMins + " mins)"), value: "Video" },
						)
					}

					if(conf.connectionTypes.includes('Phone'))
					{
						opt.push({ "name": translate("Click to call", "Haga clic para llamar"), "value": "Click to call" })
					}

					if(conf.connectionTypes.includes('Online'))
					{
						opt.push({ value: "Other options", name: translate("Other options", "Otras opciones") })
					}
					
				}
				else if (response.data.offerBooking) {
					// offer connection options: video (booking only), c2c, other

					if(conf.connectionTypes.includes('Video'))
					{
						opt.push({ "name": translate("Video (booking only)", "Vídeo (sólo reservas)"), value: "Video (Booking)" },)
					}

					if(conf.connectionTypes.includes('Phone'))
					{
						opt.push({ "name": translate("Click to call", "Haga clic para llamar"), "value": "Click to call" })
					}

					if(conf.connectionTypes.includes('Online'))
					{
						opt.push({ value: "Other options", name: translate("Other options", "Otras opciones") })
					}
				}
				else {
					// offer connection options: c2c, other
					if(conf.connectionTypes.includes('Phone'))
					{
						opt.push({ "name": translate("Click to call", "Haga clic para llamar"), "value": "Click to call" })
					}

					if(conf.connectionTypes.includes('Online'))
					{
						opt.push({ value: "Other options", name: translate("Other options", "Otras opciones") })
					}
				
				}

				setConnectionOptions(opt)
				setShowLoading(false)
				jumpToEnd()
			}
		}
	}

	const generateCode = async () => {

		console.log("CAZ calling generatecode")
		let code = ""

		const adjectives = ["Active", "Agile", "Amiable", "Ample", "Apt", "Artful", "Astute", "Avid", "Awake", "Aware", "Beloved", "Blissful", "Brave", "Breezy", "Bright",
			"Buoyant", "Candid", "Capable", "Careful", "Cheery", "Chirpy", "Clever", "Comely", "Comfy", "Crisp", "Cuddly", "Dainty", "Darling", "Dazzle", "Delish",
			"Dimple", "Doting", "Dreamy", "Dynamic", "Earnest", "Easy",
			"Exotic", "Expert", "Fabled", "Faith", "Fancy", "Fierce", "Fine", "Fond", "Free", "Fresh", "Friendly", "Frolic", "Fun", "Funny", "Gentle", "Gifted", "Glorious",
			"Grateful", "Happy", "Hardy", "Hearty", "Honest", "Huge", "Humble", "Ideal", "Innocent", "Jazzy", "Jolly", "Keen", "Kind", "Lively", "Lovely", "Loving",
			"Lucky", "Merry", "Mighty", "Modest", "Neat", "Noble", "Peace", "Playful", "Plucky", "Posh", "Pure", "Quick", "Radiant", "Rare", "Refined", "Regal", "Relish", "Rich",
			"Robust", "Rosy", "Secure", "Serene", "Sharp", "Shiny", "Simple", "Sincere", "Skill", "Sleek", "Smart", "Smitten", "Snappy", "Snug", "Solid", "Sonic",
			"Sought", "Speedy", "Spry", "Square", "Stable", "Steady", "Sunny", "Super", "Supreme", "Sure", "Sweet", "Swift", "Swish", "Talented", "Tender",
			"Tickled", "Toasty", "Top"
		]

		const animals = [
			"Badger", "Beaver", "Bison", "Bobcat", "Buffalo", "Bulldog", "Butterfly", "Capybara", "Cheetah", "Chimp",
			"Chipmunk", "Cicada", "Cobra", "Corgi", "Cougar", "Crab", "Crane", "Crayfish", "Crow", "Deer",
			"Dingo", "Dolphin", "Donkey", "Dragonfly", "Eagle", "Eel", "Elephant", "Elk", "Falcon", "Ferret",
			"Finch", "Firefly", "Flamingo", "Fly", "Fox", "Frog", "Gazelle", "Gecko", "Gibbon", "Giraffe",
			"Goat", "Goose", "Gorilla", "Greyhound", "Guppy", "Hamster", "Hare", "Hawk",
			"Hedgehog", "Heron", "Hippo", "Horse", "Hyena", "Iguana", "Impala", "Jackal", "Jaguar",
			"Jay", "Jellyfish", "Kangaroo", "Koala", "Komodo", "Ladybug", "Lemur", "Leopard", "Lion", "Lizard",
			"Llama", "Lobster", "Macaw", "Magpie", "Manatee", "Mantis", "Marmot", "Meerkat", "Mongoose", "Monkey",
			"Moose", "Mosquito", "Moth", "Narwhal", "Newt", "Ocelot", "Octopus", "Opossum", "Orca",
			"Ostrich", "Otter", "Owl", "Panda", "Panther", "Parrot", "Peacock", "Pelican", "Penguin", "Pig",
			"Platypus", "Poodle", "Porcupine", "Possum", "Puma", "Puppy", "Rabbit", "Raccoon",
			"Rat", "Raven", "Rhino", "Rooster", "Salmon", "Scorpion", "Seagull", "Seahorse",
			"Seal", "Shark", "Sheep", "Shrimp", "Skunk", "Sloth", "Snail", "Snake", "Sparrow", "Squid",
			"Squirrel", "Starling", "Stingray", "Stork", "Swan", "Tamarin", "Tiger", "Toad", "Tortoise",
			"Turkey", "Turtle", "Viper", "Vulture", "Wallaby", "Walrus", "Warthog", "Wasp", "Weasel", "Whale",
			"Wolf", "Wombat", "Wren", "Yak", "Zebra"
		]

		// generate code
		code = adjectives[Math.floor(Math.random() * adjectives.length)].toLowerCase() + " " + animals[Math.floor(Math.random() * animals.length)].toLowerCase()

		// validate code
		const url = "https://video-campaigns-6267.twil.io/" + domain + "/get-connection-by-code"

		const conn: any = {}

		const config: AxiosRequestConfig = {
			method: "post",
			maxBodyLength: Infinity,
			url,
			headers: {
				"Content-Type": "application/json"
			},
			data: JSON.stringify({
				useProd: conf.useProd,
				data: JSON.stringify({ "code": code })
			})
		};


		axios(config)
			.then((response1: any) => {
				console.log("found connection", JSON.stringify(response1.data));
				if (Object.keys(response1.data).includes("error_message")) {
					// it's unique!
					return code
				}
				else {
					// not unique, generate another
					console.log("not unique")
					generateCode()
				}
			})
			.catch((error: any) => {
				console.log("caz", error);
			});


		return code
	};


	const callVideoLocator = async () => {
		setVideoLoading(true)
		console.log("call video locator")


		window.addEventListener("message", (e) => {

			if (isCalendlyEvent(e)) {
				console.log("calendly", e.data);
				if (e.data.event == "calendly.event_scheduled") {
					setCalendlyCompleted(true)
					jumpToEnd()
					const updateAppointment = async () => {
						// create dummy appointment
						const url = e.data.payload.event.uri

						// update ConnectionAgent
						const config: AxiosRequestConfig = {
							method: "post",
							maxBodyLength: Infinity,
							url: "https://video-campaigns-6267.twil.io/" + domain + "/update-appointment",
							headers: {
								"Content-Type": "application/json"
							},
							data: JSON.stringify({
								useProd: conf.useProd,
								data: JSON.stringify({ "url": url, "agencyServiceConfigId": agencyServiceConfigIdRef.current, "connectionAgent": newConnectionAgentRef.current })
							})

						}
						try {
							const response = await axios.request(config);
						}
						catch (error) {
							console.error(error)
						}
					}

					// call the function
					updateAppointment()
						// make sure to catch any error
						.catch(console.error);
				}
			}
		})

		const d = {
			...locatorDetails,
			duplicateOverride: false,
			availabilityResults: availability,
			service: locatorDetails.serviceName,
			videoCampaignId: conf.videoCampaignId,
			email: locatorDetails.emailAddress,
			phone: locatorDetails.phoneNumber,
			language: conf.language
		}

		const config: AxiosRequestConfig = {
			method: "post",
			maxBodyLength: Infinity,
			url: "https://video-campaigns-6267.twil.io/" + domain + "/call-locator-video",
			headers: {
				"Content-Type": "application/json"
			},
			data: JSON.stringify({ useProd: false, data: JSON.stringify(d) })
		};
		const response = await axios.request(config);
		console.log("video locator data", response.data)
		setLocatorResults(response.data)
		setAgencyServiceConfigId(response.data.agencyServiceConfigId)
		setVideoLoading(false)

		if (response.data.available && connectionOptionRef.current?.startsWith("Video")) {


			startVideo(response.data)
		}
		else if (connectionOptionRef.current?.startsWith("Video")) {
			jumpToEnd()
			const url = "https://video-campaigns-6267.twil.io/" + domain + "/create-appointment"

			// const c = response.data.connectionId.toString() + Math.ceil(Math.random() * 10000);
			const c = await generateCode()

			const ca: any = {
				connectionId: response.data.connectionId,
				agencyId: response.data.agencyId,
				code: c
			}

			const config: AxiosRequestConfig = {
				method: "post",
				maxBodyLength: Infinity,
				url,
				headers: {
					"Content-Type": "application/json"
				},
				data: JSON.stringify({
					useProd: conf.useProd,
					data: JSON.stringify(ca)
				})
			};
			try {
				const response1 = await axios.request(config);
				ca.connectionAgentId = response1.data.connectionAgentId
				setNewConnectionAgent(ca)

			}
			catch (error) {
				console.error(error)
			}
		}

	}



	const callLocator = async () => {
		console.log("callLocator");

		const isError = validate()

		if (!isError) {
			setStep(step + 1);

			const data = JSON.stringify({
				"service": locatorDetails.serviceName,
				"language": conf.language ?? "English",
				"zipCode": locatorDetails.zipCode,
				"email": locatorDetails.emailAddress,
				"phone": locatorDetails.phoneNumber,
				"firstName": locatorDetails.firstName,
				"lastName": locatorDetails.lastName,
				"useProd": conf.useProd
			});

			let url = "https://chat-widget-5710.twil.io/proxy-request-nfcc-org"

			if (window.location.href.includes("localhost")) {
				url = "https://chat-widget-5710.twil.io/proxy-request"
			}
			else if (window.location.href.includes("pages.dev")) {
				url = "https://chat-widget-5710.twil.io/proxy-request-pages-dev"
			}


			const config = {
				method: "post",
				url,
				headers: {
					"Content-Type": "application/json"
				},
				data: data
			};

			axios(config)
				.then((response: any) => {
					setLocatorResults(response.data)
					console.log(response.data)

					setStep(6);
					console.log("found agency")
					jumpToEnd()

					window.addEventListener("message", (e) => {

						if (isCalendlyEvent(e)) {
							console.log("calendly", e.data);
							if (e.data.event == "calendly.event_scheduled") {
								setCalendlyCompleted(true)
								jumpToEnd()
							}
						}
					})
				})
				.catch((error: any) => {

					if (error.response) {
						if (error.response.data) {
							if (error.response.data === "Invalid zipCode") {

								setLocatorError(translate("Looks like you entered an invalid zip code, please go back and try again", "Parece que ingresó un código postal no válido, regrese e intente nuevamente"))

							}
						}
					}

					setStep(99);
					console.log("error finding agency")
					jumpToEnd()
				});


		}
		else {
			console.log("unknown error")
			setStep(99);
			console.log("error finding agency")
			jumpToEnd()
		}

	};

	const followOption = async (option: string) => {

		console.log(option)


		setConnectionOption(option)


		if (option == "Video") {

			checkPermissions()
			jumpToEnd()
		}
		else if (option.startsWith("Video")) {
			callVideoLocator()
		}
		else if (option === "Click to call") {

			console.log("click to call!", voice, voiceStatus)
			setTimeout(() => { playDial() }, 400)


			// create a dummy voice connection, put the form details in there. These are pulled by the studio flow to default the options

			// generate a guid
			let d = new Date().getTime();
			let d2 = ((typeof performance !== "undefined") && performance.now && (performance.now() * 1000)) || 0;
			const flowSid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
				let r = Math.random() * 16;
				if (d > 0) {
					r = (d + r) % 16 | 0;
					d = Math.floor(d / 16);
				} else {
					r = (d2 + r) % 16 | 0;
					d2 = Math.floor(d2 / 16);
				}
				return (c === "x" ? r : (r & 0x3 | 0x8)).toString(16);
			});

			const data = JSON.stringify({ // TODO remove hardcoding
				"callSid": flowSid,
				"connectionType": "voice-dummy",
				"fromPhone": locatorDetails.phoneNumber,
				"fromEmail": locatorDetails.emailAddress,
				"duplicateCheckResult": 0,
				"serviceName": locatorDetails.serviceName,
				"serviceId": locatorDetails.serviceId,
				"isGeolocated": 0,
				"agencyId": 0,
				"failed": 1,
				"failureReason": "Dummy voice from chatbot",
				"lastIVRState": "",
				"firstName": locatorDetails.firstName,
				"lastName": locatorDetails.lastName,
				"zipCode": locatorDetails.zipCode,
				"language": locatorDetails.language
			});

			const config = {
				method: "post",
				url: "https://chat-widget-5710.twil.io/" + domain + "/create-dummy-connection",
				headers: {
					"Content-Type": "application/json"
				},
				data: data
			};

			axios(config)
				.then((response: any) => {
					if (connectionOptionRef.current?.startsWith("Click")) {
						voiceInit(flowSid);
					}


				})
				.catch((error: any) => {
					console.log(error); // TODO handle error
				});


		}
		else {
			// setConnectionOptions(undefined)
			callLocator()
		}
	}

	const showBooking = () => {
		setStep(8);
		setTimeout(() => {
			if (bookingWidgetDivRef.current) {
				bookingWidgetDivRef.current.scrollIntoView({ behavior: "smooth" });

			}
		}, 1000)
	};

	const checkPermissions = () => {
		console.log("checkPermissions")
		setCheckComplete(false)


		requestMediaPermissions().then(() => {

			navigator.mediaDevices
				.getUserMedia({ video: true, audio: true })

			// get devices
			navigator.mediaDevices
				.enumerateDevices()
				.then((devices) => {

					const ai: any[] = []
					const ao: any[] = []
					const vi: any[] = []

					const ps = [];

					ps.push(
						new Promise((resolve, reject) => {
							devices.forEach((device) => {

								console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
								if (device.kind === "audioinput") {
									ai.push(device)
									if (device.deviceId === "default") {
										setAudioDeviceInput(JSON.stringify(device))
										localStorage.setItem("input_device", JSON.stringify(device));
									}
								}
								else if (device.kind === "audiooutput") {
									ao.push(device)
									if (device.deviceId === "default") {
										setAudioDeviceOutput(JSON.stringify(device))
										localStorage.setItem("speaker_device", JSON.stringify(device));
									}
								}
								else if (device.kind === "videoinput") {
									vi.push(device)
									if (device.deviceId === "default") {
										setVideoDeviceInput(JSON.stringify(device))
										localStorage.setItem("video_device", JSON.stringify(device));
									}
								}
								else {
									console.log("???", device)
								}
							}
							);
							resolve("done")
						}))

					Promise.all(ps).then((i) => {
						console.log("promise done")
						setAudioDevicesInput(ai)
						setAudioDevicesOutput(ao)
						setVideoDevicesInput(vi)
					})
				})
				.catch((err) => {
					console.error(`${err.name}: ${err.message}`);
				});
			// can successfully access camera and microphone streams
			console.log("yay")
			setPermissionsError({})
			setHasAllPermissions(true)
			jumpToEnd()
		})
			.catch((err: MediaPermissionsError) => {
				const { type, name, message } = err;
				if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
					// browser does not have permission to access camera or microphone
					console.log("browser does not have permission to access camera or microphone")
					setPermissionsError({ fallback: true })
				} else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
					// user didn't allow app to access camera or microphone
					console.log("user did not allow app to access camera or microphone")
					setPermissionsError({
						"title": translate("Camera and microphone are blocked", "Cámara y micrófono bloqueados"),
						"message": translate("This app requires access to your camera and microphone. Click the camera blocked icon in your browser's address bar and grant access to camera and microphone",
							"Esta aplicación requiere acceso a tu cámara y micrófono. Haga clic en el icono de cámara bloqueada en la barra de direcciones del navegador y conceda acceso a la cámara y al micrófono.")
					})

				} else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
					// camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)
					// (mostly Windows specific problem)
					console.log("camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)")

					setPermissionsError({
						"title": translate("Can't start your camera or microphone", "No se puede iniciar la cámara o el micrófono"),
						"message": translate("Another application or browser tab might already by using your webcam. Please turn off other cameras before proceeding",
							"Es posible que otra aplicación o pestaña del navegador ya esté utilizando tu webcam. Por favor, apague otras cámaras antes de proceder")
					})


				} else {
					// not all error types are handled by this library
					console.log("other")
					setPermissionsError({ fallback: true })
				}
			})
			.catch((err: any) => { console.log("other error", err) });
		setCheckComplete(true)
		jumpToEnd()
	}

	return (
		<CallContainer id='callContainer' >
			{" "}
			{
				isVideo && !ended &&
				<Box display="flex" flexDirection="column" rowGap="space100" alignItems="center" maxWidth="95%" margin="auto" paddingTop="space0">

					<Box position="relative" alignContent="center" maxWidth="95%">
						<Box ref={localVideoMedia} position="absolute" width={["50%", "35%", "20%"]} borderRadius="borderRadius30" overflow="hidden" zIndex="zIndex90" top="0" left="0" hidden={!attended}>
						</Box>
						{!attended && <Box position="relative" minHeight="400px">
							<Box display="flex" flexDirection="column" rowGap="space100" alignItems="center" width="60%" textAlign="center" margin="auto">

								<Text as="h3" fontSize="fontSize50" paddingTop="space120" fontWeight="fontWeightMedium" lineHeight="lineHeight80">
									{translate("You're in a queue, please hold and we will be with you soon", "Usted está en cola de espera, por favor espere y le atenderemos pronto.")}
								</Text>

								<Spinner size="sizeIcon110" decorative={false} title="Loading" />
							</Box>
						</Box>}
						<Box ref={remoteVideoMedia} position="relative" borderRadius="borderRadius30" overflow="hidden" zIndex="zIndex50">
						</Box>
					</Box>

					{/* <Box display="flex" columnGap="space80" rowGap="space100" alignItems="center" maxHeight="36px" width="max-content">*/}
					<Box display="flex" columnGap={["space40", "space80"]} rowGap="space100" alignItems="center" justifyContent="center" width="max-content" bottom={["10px", "20px", "30px"]} position="fixed" zIndex="zIndex90">

						{!isMuted && <Button onClick={() => video?.mute()} variant="secondary">
							<MicrophoneOffIcon aria-label="Mute Microphone" title="Mute Microphone" decorative={false} /> {translate("Mute", "Mudo")}
						</Button>}
						{isMuted && <Button onClick={() => video?.unmute()} variant="secondary">
							<MicrophoneOnIcon aria-label="Unmute Microphone" title="Unmute Microphone" decorative={false} /> {translate("Unmute", "Activar")}
						</Button>}
						<Button id='end-call' name="end-call" variant="secondary" onClick={handleDisconnect}>
							{ending && <Box margin="space20"><Spinner color="colorTextInverse" size="sizeIcon60" decorative={false} title="Ending call" /></Box> ||
								<> <CallFailedIcon decorative={false} title="End Call icon" /> {translate("End Call", "Finalizar llamada")}</>}
						</Button>
					</Box>
				</Box>
			}
			{(!isVideo || ended) && conf && <ChatLog>
				{step >= 0 && !isSingle && (
					<>
						<ChatBookend>
							<ChatBookendItem><Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">{translate("Today", "Hoy")}</Text></ChatBookendItem>
							<ChatBookendItem>
								<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
									<strong role="heading" aria-level={1}>{translate("Chat Started with", "Charla comenzó con")} Penny</strong> ・ {startTime}
								</Text>
							</ChatBookendItem>
						</ChatBookend>
						<ChatMessage variant="inbound">
							<ChatBubble>{translate("Hello! What can I help you with?", "¡Hola! ¿En qué puedo ayudarte?")}</ChatBubble>
							<ChatMessageMeta aria-label={chatMessageAriaPenny}>
								<ChatMessageMetaItem>
									<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
										Penny
									</Text>
								</ChatMessageMetaItem>
							</ChatMessageMeta>
						</ChatMessage>

						{step == 0 && conf.showQ1 && (
							<FadeIn>
								<Box
									display="flex"
									paddingTop="space50"
									justifyContent="center"
									alignItems="center"
								>
									<Button
										variant="secondary"
										fullWidth={true}
										onClick={(e) => stepZeroOnChange("Credit Card Help")}
									>
										{conf && translate(conf.services.find((x: any) => x.key === "Credit Counseling").value, conf.services.find((x: any) => x.key === "Credit Counseling").spanishValue)}


									</Button>
								</Box>
								<Box
									display="flex"
									paddingTop="space50"
									justifyContent="center"
									alignItems="center"
								>
									<Button
										variant="secondary"
										fullWidth={true}
										onClick={(e) => stepZeroOnChange("Overall Financial Review")}
									>

										{conf && translate(conf.services.find((x: any) => x.key === "Overall Budget and Financial Review").value, conf.services.find((x: any) => x.key === "Overall Budget and Financial Review").spanishValue)}


									</Button>
								</Box>
								<Box
									display="flex"
									paddingTop="space50"
									justifyContent="center"
									alignItems="center"
								>
									<Button
										variant="secondary"
										fullWidth={true}
										onClick={(e) => stepZeroOnChange("More Options")}
									>

										{translate("More Options", "Mas opciones")}
									</Button>
								</Box>
							</FadeIn>
						)}
					</>
				)}
				{step >= 1 && !isSingle && conf.showQ1 && (
					<Box display="flex" justifyContent="flex-end">
						<Stack orientation="horizontal" spacing="space10">
							<Box marginBottom="space60">
								<Button variant="reset" size="reset" onClick={(e) => setStepNumber(0)}>
									<EditIcon decorative={false} title="edit" /> {back}</Button></Box>
							<ChatMessage variant="outbound">
								<ChatBubble>{stepZeroSelection}</ChatBubble>
								<ChatMessageMeta aria-label={chatMessageAriaSelf}>
									<ChatMessageMetaItem>
										<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
											{you}
										</Text>
									</ChatMessageMetaItem>
								</ChatMessageMeta>
							</ChatMessage>
						</Stack></Box>
				)}

				{step == 2 && !isSingle && (
					<><FadeIn>
						{conf.showQ1 && <ChatMessage variant="inbound">
							<ChatBubble>

								{translate("Please choose from other counseling options below:", "Por favor, elija entre estas otras opciones de asesoramiento")}
							</ChatBubble>
							<ChatMessageMeta aria-label={chatMessageAriaPenny}>
								<ChatMessageMetaItem>
									<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
										Penny
									</Text>
								</ChatMessageMetaItem>
							</ChatMessageMeta>
						</ChatMessage>}

						{step == 2 && (
							<Box
								display="flex"
								paddingTop="space50"
								justifyContent="center"
								alignItems="center"
							>
								<Select id="serviceName" onChange={(e) => onChangeIncrement(e)} >

									<Option value="default">
										{translate("-- Select a service --", "-- Seleccione un servicio --")}
									</Option>

									{conf
										? conf &&
										conf.otherServices.map((service: any, index: number) => (
											<Option key={index} value={service.key + "#" + service.serviceId}>
												{translate(service.value, service.spanishValue)}
											</Option>
										))
										: null!}



								</Select></Box>
						)}
					</FadeIn></>
				)}

				{step >= 3 && (
					<>


						{["More Options", "Mas opciones"].includes(stepZeroSelection) && !isSingle && <ChatMessage variant="inbound">
							<ChatBubble>
								{translate("Please choose from other counseling options below:", "Por favor, elija entre estas otras opciones de asesoramiento")}
							</ChatBubble>
							<ChatMessageMeta aria-label={chatMessageAriaPenny}>
								<ChatMessageMetaItem>
									<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
										Penny
									</Text>
								</ChatMessageMetaItem>
							</ChatMessageMeta>
						</ChatMessage>}

						{locatorDetails.serviceName && !isSingle && ["", "More Options", "Mas opciones"].includes(stepZeroSelection) && (<>
							<Box display="flex" justifyContent="flex-end">
								<Stack orientation="horizontal" spacing="space10">
									<Box marginBottom="space60">
										<Button variant="reset" size="reset" onClick={(e) => setStepNumber(2)}>
											<EditIcon decorative={false} title="edit" /> {back}</Button></Box>
									<ChatMessage variant="outbound">


										<ChatBubble>{

											conf.language === "Spanish" && conf.otherServices.find((x: any) => x.key === locatorDetails?.serviceName).spanishValue || conf.otherServices.find((x: any) => x.key === locatorDetails?.serviceName).value

										}

										</ChatBubble>



										<ChatMessageMeta aria-label={chatMessageAriaSelf}>
											<ChatMessageMetaItem>
												<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
													{you}
												</Text>
											</ChatMessageMetaItem>
										</ChatMessageMeta>
									</ChatMessage></Stack></Box>
						</>)}
						{isSingle && <ChatMessage variant="inbound">
							<ChatBubble>
								{translate("Hello! Can I help connect you with a member agency to discuss your financial situation?", "¡Hola! ¿Puedo ayudarle a conectarse con una agencia asociada para discutir su situación financiera?")}

							</ChatBubble>
							<ChatMessageMeta aria-label={chatMessageAriaPenny}>
								<ChatMessageMetaItem>
									<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
										Penny
									</Text>
								</ChatMessageMetaItem>
							</ChatMessageMeta>
						</ChatMessage>}
						{isSingle && !singleContinue && <>

							<Box
								display="flex"
								paddingTop="space50"
								padding="space30"
							>


								<Button variant="secondary" fullWidth={true} onClick={(e) => cont()}>
									{yes}
								</Button><Box
									display="flex"
									paddingTop="space50"
									padding="space10"
								/>
								<Button variant="secondary" fullWidth={true} onClick={(e) => close()}>
									{no}
								</Button>
							</Box>
						</>}
						<FadeIn>
							{singleContinue && isSingle && <ChatMessage variant="outbound">
								<ChatBubble>{yes}</ChatBubble>
								<ChatMessageMeta aria-label={chatMessageAriaSelf}>
									<ChatMessageMetaItem>
										<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
											{you}
										</Text>
									</ChatMessageMetaItem>
								</ChatMessageMeta>
							</ChatMessage>}
							{singleContinue &&
								<ChatMessage variant="inbound">
									<ChatBubble>
										{translate("Great, let's get started! What is your name?", "¡Bueno, empecemos! ¿Cómo te llamas?")}

									</ChatBubble>
									<ChatMessageMeta aria-label={chatMessageAriaPenny}>
										<ChatMessageMetaItem>
											<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
												Penny
											</Text>
										</ChatMessageMetaItem>
									</ChatMessageMeta>
								</ChatMessage>}

							{step == 3 && singleContinue && (
								<FadeIn>
									<Box
										display="flex"
										paddingTop="space50"
										justifyContent="center"
										alignItems="center"
									>
										<Input
											aria-describedby="firstName"
											id="firstName"
											name="firstName"
											type="text"
											placeholder={translate("First name", "Nombre")}
											onChange={(e) => onChange(e)}
											required
											value={locatorDetails.firstName}
										/></Box>
									<Box
										display="flex"
										paddingTop="space50"
										justifyContent="center"
										alignItems="center"
									>
										<Input
											aria-describedby="lastName"
											id="lastName"
											name="lastName"
											type="text"
											placeholder={translate("Last name", "Apellido")}
											onChange={(e) => onChange(e)}
											required
											value={locatorDetails.lastName}
										/></Box>
									<Box
										display="flex"
										paddingTop="space50"
										justifyContent="center"
										alignItems="center"
									>
										<Button variant="primary" onClick={(e) => increment()} disabled={!locatorDetails.firstName || !locatorDetails.lastName}>
											{next}
										</Button></Box></FadeIn>

							)}
						</FadeIn></>
				)}

				{step >= 4 && (
					<>
						<Box display="flex" justifyContent="flex-end">
							<Stack orientation="horizontal" spacing="space10">
								<Box marginBottom="space60">
									<Button variant="reset" size="reset" onClick={(e) => setStepNumber(3)}>
										<EditIcon decorative={false} title="edit" /> {back}</Button></Box>
								<ChatMessage variant="outbound">
									<ChatBubble>
										{locatorDetails?.firstName + " " + locatorDetails?.lastName}
									</ChatBubble>
									<ChatMessageMeta aria-label={chatMessageAriaSelf}>
										<ChatMessageMetaItem>
											<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
												{you}
											</Text>
										</ChatMessageMetaItem>
									</ChatMessageMeta>
								</ChatMessage></Stack></Box>

						<ChatMessage variant="inbound">
							<ChatBubble>
								{translate("Let's get some more information", "Vamos a obtener más información")}

							</ChatBubble>
							<ChatMessageMeta aria-label={chatMessageAriaPenny}>
								<ChatMessageMetaItem>
									<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
										Penny
									</Text>
								</ChatMessageMetaItem>
							</ChatMessageMeta>
						</ChatMessage>

						{step == 4 && !connectionOptions && !showLoading && (
							<>
								<FadeIn>
									<Box
										display="flex"
										paddingTop="space50"
										justifyContent="center"
									>
										<Input
											aria-describedby="zipCode"
											id="zipCode"
											name="zipCode"
											type="text"
											placeholder={translate("ZIP", "Código postal")}
											onChange={(e) => onChange(e)}
											required
											value={locatorDetails.zipCode}
											hasError={zipError.length > 0}
										/></Box>
									{zipError.length > 0 && <HelpText id="zip_help_text" variant="error">{zipError}</HelpText>}
									<Box
										display="flex"
										paddingTop="space50"
										justifyContent="center"
										alignItems="center"
									>
										<Input
											aria-describedby="phoneNumber"
											id="phoneNumber"
											name="phoneNumber"
											type="tel"
											placeholder={translate("Phone", "Teléfono")}
											onChange={(e) => onChange(e)}
											required
											value={locatorDetails.phoneNumber}
											hasError={phoneError.length > 0}
										/>

									</Box>{phoneError.length > 0 && <HelpText id="phone_help_text" variant="error">{phoneError}</HelpText>}
									<Box
										display="flex"
										paddingTop="space50"
										justifyContent="center"
										alignItems="center"
									>
										<Input
											aria-describedby="emailAddress"
											id="emailAddress"
											name="emailAddress"
											type="email"
											placeholder={translate("Email", "Correo electrónico")}
											onChange={(e) => onChange(e)}
											required
											value={locatorDetails.emailAddress}
											hasError={emailError.length > 0}
										/></Box>
									{emailError.length > 0 && <HelpText id="email_help_text" variant="error">{emailError}</HelpText>}
									<Box
										display="flex"
										paddingTop="space50"
										justifyContent="center"
										alignItems="center"
									>
										<Checkbox
											checked={consent}
											id="consent"
											value="consent"
											name="consent"
											onChange={(event) => {
												setConsent(event.target.checked);
											}}
										>
											{translate("The NFCC and its member agencies may contact me by phone, email or text", "La NFCC y sus agencias miembros pueden comunicarse conmigo por teléfono, correo electrónico o mensaje de texto.")}


										</Checkbox></Box>
									<Box
										display="flex"
										paddingTop="space50"
										justifyContent="center"
										alignItems="center"
									>
										<Button variant="primary" onClick={(e) => checkVideoAvailability()} disabled={!consent}>
											{next}
										</Button></Box></FadeIn>

							</>
						)}
					</>
				)}
				{(step >= 5 || connectionOptions || showLoading) && (
					<><FadeIn>
						<Box display="flex" justifyContent="flex-end">
							<Stack orientation="horizontal" spacing="space10">
								<Box marginBottom="space60">
									<Button variant="reset" size="reset" onClick={(e) => setStepNumber(4)}>
										<EditIcon decorative={false} title="edit" /> {back}</Button></Box>
								<ChatMessage variant="outbound">
									<ChatBubble>
										<div>
											{translate("ZIP", "Código postal") + ": " +
												locatorDetails?.zipCode}
										</div>
										<div>{translate("Email", "Correo electrónico") + ": " +
											locatorDetails?.emailAddress}</div>
										<div>{translate("Phone", "Teléfono") + ": " +
											locatorDetails?.phoneNumber}</div>

									</ChatBubble>
									<ChatMessageMeta aria-label={chatMessageAriaSelf}>
										<ChatMessageMetaItem>
											<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
												{you}
											</Text>
										</ChatMessageMetaItem>
									</ChatMessageMeta>
								</ChatMessage></Stack></Box>



					</FadeIn>


					</>
				)}
				{showLoading && <FadeIn>
					<ChatMessage variant="inbound">
						<ChatBubble>
							{translate("Okay, great, we're checking our availability now...", "Vale, genial, ahora comprobamos nuestra disponibilidad...")}
							<Box display='flex' alignItems="center" paddingTop="space20" paddingBottom="space20" justifyContent="center"><Spinner size="sizeIcon60" decorative={false} title="Loading" /></Box>

						</ChatBubble>
						<ChatMessageMeta aria-label={chatMessageAriaPenny}>
							<ChatMessageMetaItem>
								<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
									Penny
								</Text>
							</ChatMessageMetaItem>
						</ChatMessageMeta>
					</ChatMessage></FadeIn>}
				{connectionOptions && <>
					<FadeIn>
						<Box display="flex" justifyContent="flex-start"><ChatMessage variant="inbound">
							<ChatBubble>
								{translate("Okay, great, we're checking our availability now...", "Vale, genial, ahora comprobamos nuestra disponibilidad...")}

							</ChatBubble>
							<ChatMessageMeta aria-label={chatMessageAriaPenny}>
								<ChatMessageMetaItem>
									<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
										Penny
									</Text>
								</ChatMessageMetaItem>
							</ChatMessageMeta>
						</ChatMessage></Box>
						<Box display="flex" justifyContent="flex-start" paddingTop="space40"><ChatMessage variant="inbound" >
							<ChatBubble>
								{translate("We've got some options for you", "Tenemos algunas opciones para usted")}

							</ChatBubble>
							<ChatMessageMeta aria-label={chatMessageAriaPenny}>
								<ChatMessageMetaItem>
									<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
										Penny
									</Text>
								</ChatMessageMetaItem>
							</ChatMessageMeta>
						</ChatMessage></Box>
						{connectionOptions
							? connectionOptions && !connectionOption &&
							connectionOptions.map((o: any, index: number) => (
								<Box
									key={"box" + index}
									display="flex"
									paddingTop="space50"
									justifyContent="center"
									alignItems="center"
								>
									<Button
										key={"button" + index}
										variant="secondary"
										fullWidth={true}
										onClick={(e) => followOption(o.value)}
										value={o.value}
									>
										{o.name}
									</Button>
								</Box>
							))
							: null!}


					</FadeIn>
				</>}
				{connectionOption && (
					<Box display="flex" justifyContent="flex-end">
						<Stack orientation="horizontal" spacing="space10">
							<Box marginBottom="space60">
								<Button variant="reset" size="reset" onClick={(e) => { setConnectionOption(undefined); jumpToEnd() }}>
									<EditIcon decorative={false} title="edit" /> {back}</Button></Box>
							<ChatMessage variant="outbound">
								<ChatBubble>{connectionOptions && connectionOptions.find((x: any) => x.value === connectionOption).name}</ChatBubble>
								<ChatMessageMeta aria-label={chatMessageAriaSelf}>
									<ChatMessageMetaItem>
										<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
											{you}
										</Text>
									</ChatMessageMetaItem>
								</ChatMessageMeta>
							</ChatMessage>
						</Stack></Box>
				)}
				{connectionOption?.startsWith("Video") && <FadeIn>
					<Box display="flex" justifyContent="flex-start" marginBottom="space60">
						<ChatMessage variant="inbound">
							<ChatBubble>
								{translate("Finding you a video agency now, please wait", "Encontrar una agencia de vídeo ahora, por favor espere")}
								{videoLoading && !hideSpinner && <Box display='flex' alignItems="center" paddingTop="space20" paddingBottom="space20" justifyContent="center"><Spinner size="sizeIcon60" decorative={false} title="Loading" /></Box>}

							</ChatBubble>
							<ChatMessageMeta aria-label={chatMessageAriaPenny}>
								<ChatMessageMetaItem>
									<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
										Penny
									</Text>
								</ChatMessageMetaItem>
							</ChatMessageMeta>
						</ChatMessage></Box></FadeIn>}
				{connectionOption == "Click to call" && <FadeIn>
					<Box display="flex" justifyContent="flex-start" marginBottom="space60">
						<ChatMessage variant="inbound">
							<ChatBubble>
								{translate("Connecting now, please wait", "Conectando ahora, por favor espere")}
								{voiceStatus != "open" && voiceStatus != "closed" && <Box display='flex' alignItems="center" paddingTop="space20" paddingBottom="space20" justifyContent="center"><Spinner size="sizeIcon60" decorative={false} title="Loading" /></Box>}

							</ChatBubble>
							<ChatMessageMeta aria-label={chatMessageAriaPenny}>
								<ChatMessageMetaItem>
									<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
										Penny
									</Text>
								</ChatMessageMetaItem>
							</ChatMessageMeta>
						</ChatMessage></Box></FadeIn>}
				{connectionOption == "Click to call" && voiceStatus != "pending" && <FadeIn>
					<Box display="flex" justifyContent="flex-start" marginBottom="space60">
						<ChatMessage variant="inbound">
							<ChatBubble>
								{translate("Found you an agency, dialing now", "Te encontré una agencia, marcando ahora")}

							</ChatBubble>
							<ChatMessageMeta aria-label={chatMessageAriaPenny}>
								<ChatMessageMetaItem>
									<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
										Penny
									</Text>
								</ChatMessageMetaItem>
							</ChatMessageMeta>
						</ChatMessage></Box></FadeIn>}

				{/* call buttons*/
					voice &&
					voiceStatus == "open" &&
					<Box
						display="flex"
						paddingTop="space50"
						padding="space30"
					>


						<Button variant="secondary" fullWidth={true} onClick={(e) => voice.call.mute(!voiceIsMuted)}>
							{voiceIsMuted && translate("Unmute", "Activar") || translate("Mute", "Mudo")}
						</Button><Box
							display="flex"
							paddingTop="space50"
							padding="space10"
						/>
						<Button variant="secondary" fullWidth={true} onClick={(e) => voice.call.disconnect()}>
							{translate("End Call", "Finalizar llamada")}
						</Button>
					</Box>
				}
				{connectionOption == "Other options" && <FadeIn>
					<ChatMessage variant="inbound">
						<ChatBubble>
							{translate("Looking for agencies now, please wait", "Buscando agencias ahora, por favor espere")}
							{!locatorResults && <Box display='flex' alignItems="center" paddingTop="space20" paddingBottom="space20" justifyContent="center"><Spinner size="sizeIcon60" decorative={false} title="Loading" /></Box>}

						</ChatBubble>
						<ChatMessageMeta aria-label={chatMessageAriaPenny}>
							<ChatMessageMetaItem>
								<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
									Penny
								</Text>
							</ChatMessageMetaItem>
						</ChatMessageMeta>
					</ChatMessage></FadeIn>}
				{step >= 6 && step != 99 && locatorResults && locatorResults.calendlyUrl && (<FadeIn>
					<Box
						display="flex"
						paddingTop="space50"
						padding="space30"
					></Box>
					<ChatMessage variant="inbound">
						<ChatBubble>
							{translate("You've been connected to ", "Has estado conectado a ")}<b>{locatorResults.agencyName}</b>
							{translate(". This agency has online booking, would you like to go ahead and book a counseling session?", ". Esta agencia le ofrece la opción de reservar una cita en línea, ¿le gustaría seguir adelante y reservar una sesión de asesoramiento?")}


						</ChatBubble>
						<ChatMessageMeta aria-label={chatMessageAriaPenny}>
							<ChatMessageMetaItem>
								<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
									Penny
								</Text>
							</ChatMessageMetaItem>
						</ChatMessageMeta>
					</ChatMessage>

					{step == 6 && (
						<>
							<Box
								display="flex"
								paddingTop="space50"
								padding="space30"
							>


								<Button variant="secondary" fullWidth={true} onClick={(e) => showBooking()}>
									{yes}
								</Button><Box
									display="flex"
									paddingTop="space50"
									padding="space10"
								/>
								<Button variant="secondary" fullWidth={true} onClick={(e) => increment()}>
									{no}
								</Button>
							</Box>
						</>)}
				</FadeIn>)}

				{step >= 6 && step != 99 && locatorResults && !locatorResults.calendlyUrl && (<FadeIn>
					<Box
						display="flex"
						paddingTop="space50"
						padding="space30"
					></Box>
					<ChatMessage variant="inbound">
						<ChatBubble>


							{translate("You've been connected to ", "Has estado conectado a ")}<b>{locatorResults.agencyName}</b>
							{translate(". A member of their team will be reaching out to you. You will also receive an email with other ways to get in touch.", ". Un miembro de su personal se pondrá en contacto. También recibirás un correo electrónico con otras formas de ponerse en contacto.")}

							{locatorResults.destinationType == "URL" && <> {translate("\n\nYou can get started with this agency at ", "Puede ponerse en contacto con esta agencia en ")} <a href={locatorResults.destination} rel="noreferrer" target="_blank">{locatorResults.destination}</a></>}

						</ChatBubble>
						<ChatMessageMeta aria-label={chatMessageAriaPenny}>
							<ChatMessageMetaItem>
								<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
									Penny
								</Text>
							</ChatMessageMetaItem>
						</ChatMessageMeta>
					</ChatMessage>


				</FadeIn>)}

				{step == 7 && (<>
					<Box display="flex" justifyContent="flex-end">
						<Stack orientation="horizontal" spacing="space10">
							<Box marginBottom="space60">
								<Button variant="reset" size="reset" onClick={(e) => setStepNumber(6)}>
									<EditIcon decorative={false} title="edit" /> {back}</Button></Box>
							<ChatMessage variant="outbound">
								<ChatBubble>{no}</ChatBubble>
								<ChatMessageMeta aria-label={chatMessageAriaSelf}>
									<ChatMessageMetaItem>
										<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
											{you}
										</Text>
									</ChatMessageMetaItem>
								</ChatMessageMeta>
							</ChatMessage></Stack></Box>
					<FadeIn>
						<Box
							display="flex"
							paddingTop="space50"
							padding="space30"
						></Box>
						<ChatMessage variant="inbound">
							<ChatBubble>
								{translate("No problem! A member of their team will be reaching out to you. You will also receive an email with other ways to get in touch.",
									"¡Ningún problema! Un miembro de su personal se pondrá en contacto. También recibirás un correo electrónico con otras formas de ponerse en contacto. ")}

								{locatorResults.destinationType == "URL" && <> {translate("\n\nYou can get started with this agency at ", "Puede ponerse en contacto con esta agencia en ")} <a href={locatorResults.destination} rel="noreferrer" target="_blank">{locatorResults.destination}</a></>}

							</ChatBubble>
							<ChatMessageMeta aria-label={chatMessageAriaPenny}>
								<ChatMessageMetaItem>
									<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
										Penny
									</Text>
								</ChatMessageMetaItem>
							</ChatMessageMeta>
						</ChatMessage>


					</FadeIn></>
				)}

				{connectionOption == "Video" && !isVideo && !hasAllPermissions && checkComplete &&
					<>
						<FadeIn >
							{Object.keys(permissionsError).includes("message") && <>
								<ChatMessage variant="inbound">
									<ChatBubble>
										{permissionsError.title + ": " + permissionsError.message}

									</ChatBubble>
									<ChatMessageMeta aria-label={chatMessageAriaPenny}>
										<ChatMessageMetaItem>
											<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
												Penny
											</Text>
										</ChatMessageMetaItem>
									</ChatMessageMeta>
								</ChatMessage>


								<Box
									display="flex"
									paddingTop="space50"
									justifyContent="center"
									alignItems="center"
								>
									<Button variant="primary" onClick={(e) => { checkPermissions() }}>
										{translate("Check again", "Revisar otra vez")}
									</Button></Box></>}


							{!Object.keys(permissionsError).includes("message") && <>
								<ChatMessage variant="inbound">
									<ChatBubble>
										{translate("Allow application to use your camera and microphone. ", "Permitir que la aplicación utilice la cámara y el micrófono. ")}
										{translate("The application needs access to your camera and microphone so that other participants can see and hear you", "La aplicación necesita acceso a tu cámara y micrófono para que los demás participantes puedan verte y oírte")}


									</ChatBubble>
									<ChatMessageMeta aria-label={chatMessageAriaPenny}>
										<ChatMessageMetaItem>
											<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
												Penny
											</Text>
										</ChatMessageMetaItem>
									</ChatMessageMeta>
								</ChatMessage>


							</>}

						</FadeIn>

					</>
				}

				{connectionOption == "Video" && !isVideo && hasAllPermissions &&
					<>
						<FadeIn >
							<ChatMessage variant="inbound">
								<ChatBubble>
									{translate("Please select your devices and click start",
										"Seleccione sus dispositivos y haga clic en Inicio")}

								</ChatBubble>
								<ChatMessageMeta aria-label={chatMessageAriaPenny}>
									<ChatMessageMetaItem>
										<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
											Penny
										</Text>
									</ChatMessageMetaItem>
								</ChatMessageMeta>
							</ChatMessage>

							<Box
								display="flex"
								paddingTop="space50"
								justifyContent="flex-start"
								alignItems="center"
								width='100%'
							>
								<Stack orientation="vertical" spacing="space10">
									<Label htmlFor="firstName">{translate("Microphone", "Micrófono")}</Label>
									<Select id="audioDeviceInput" name="audioDeviceInput" onChange={setDevice} value={audioDeviceInput}>

										{audioDevicesInput ? (

											audioDevicesInput && audioDevicesInput.map((s: any, index: number) => (

												<Option key={index} value={JSON.stringify(s)} >{s.label}</Option>

											))) : null!}
									</Select>
								</Stack>
							</Box>

							{audioDevicesOutput.length > 0 && <Box
								display="flex"
								paddingTop="space50"
								justifyContent="flex-start"
								alignItems="center"
								width='100%'
							>
								<Stack orientation="vertical" spacing="space10">
									<Label htmlFor="firstName">{translate("Speaker", "Altavoz")}</Label>
									<Select id="audioDeviceOutput" name="audioDeviceOutput" onChange={setDevice} value={audioDeviceOutput}>

										{audioDevicesOutput ? (

											audioDevicesOutput && audioDevicesOutput.map((s: any, index: number) => (

												<Option key={index} value={JSON.stringify(s)} >{s.label}</Option>

											))) : null!}
									</Select>
								</Stack>
							</Box>}

							<Box
								display="flex"
								paddingTop="space50"
								justifyContent="flex-start"
								alignItems="center"
								width='100%'
							>
								<Stack orientation="vertical" spacing="space10" >
									<Label htmlFor="firstName">{translate("Webcam", "Cámara")}</Label>
									<Select id="videoDeviceInput" name="videoDeviceInput" onChange={setDevice} value={videoDeviceInput}>

										{videoDevicesInput ? (

											videoDevicesInput && videoDevicesInput.map((s: any, index: number) => (

												<Option key={index} value={JSON.stringify(s)} >{s.label}</Option>

											))) : null!}
									</Select></Stack>
							</Box>

							<Box
								display="flex"
								paddingTop="space50"
								justifyContent="center"
								alignItems="center"
							>
								<Button variant="primary" onClick={(e) => { setHideSpinner(true); callVideoLocator() }} loading={hideSpinner}>
									{translate("Start", "Iniciar")}
								</Button></Box></FadeIn>

					</>
				}


				{step >= 8 && step != 99 && !calendlyCompleted && (
					<>
						<ChatMessage variant="outbound">
							<ChatBubble>{yes}</ChatBubble>
							<ChatMessageMeta aria-label={chatMessageAriaSelf}>
								<ChatMessageMetaItem>
									<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
										{you}
									</Text>
								</ChatMessageMetaItem>
							</ChatMessageMeta>
						</ChatMessage>
						<div id='bookingWidget' ref={bookingWidgetDivRef}>
							<FadeIn >
								<InlineWidget url={locatorResults.calendlyUrl} pageSettings={{
									backgroundColor: "ffffff",
									hideEventTypeDetails: true,
									hideLandingPageDetails: false,
									primaryColor: "00a2ff",
									textColor: "4d5055",
									hideGdprBanner: true
								}} prefill={{
									email: locatorDetails.emailAddress,
									firstName: locatorDetails.firstName,
									lastName: locatorDetails.lastName,
									name: locatorDetails.firstName + " " + locatorDetails.lastName + " (" + locatorDetails.serviceName + " - " + conf.language + ")", customAnswers: {

										a2: locatorDetails.serviceName,
										a3: locatorDetails.phoneNumber
									}
								}} /></FadeIn></div>
					</>
				)}

				{connectionOption?.startsWith("Video") && !isVideo && !videoLoading && ((locatorResults.calendlyUrl ?? "").length > 0) && !calendlyCompleted && (
					<>

						<div id='bookingWidget' ref={bookingWidgetDivRef}>
							<FadeIn >
								<InlineWidget url={locatorResults.calendlyUrl} pageSettings={{
									backgroundColor: "ffffff",
									hideEventTypeDetails: true,
									hideLandingPageDetails: false,
									primaryColor: "00a2ff",
									textColor: "4d5055",
									hideGdprBanner: true
								}} prefill={{
									email: locatorDetails.emailAddress,
									firstName: locatorDetails.firstName,
									lastName: locatorDetails.lastName,
									name: locatorDetails.firstName + " " + locatorDetails.lastName + " (" + locatorDetails.serviceName + " - " + conf.language + " - Video)",
									customAnswers: {
										a1: (newConnectionAgent as any).code + translate(" is your code to start your next video session.",
											" es su código para iniciar su próxima sesión de vídeo")
											+
											translate("\n\nIf you plan to connect with your counselor using your own device, please click on the following link at your scheduled appointment time: ",
												"\n\nSi planea conectarse con su consejero usando su propio teléfono celular, haga clic en el siguiente enlace a la hora programada de su cita:")
											+ (conf.useProd && "https://video.nfcc.org/a/" || "https://nfcc-video-app-build.pages.dev/a/") +
											(newConnectionAgent as any).code,
										a2: locatorDetails.serviceName,
										a3: locatorDetails.phoneNumber
									}
								}} />



							</FadeIn>
						</div>
					</>
				)}

				{step == 99 && (<>
					<FadeIn>
						<Box
							display="flex"
							paddingTop="space50"
							padding="space30"
						></Box>
						<ChatMessage variant="inbound">
							<ChatBubble>
								{locatorError}
							</ChatBubble>
							<ChatMessageMeta aria-label={chatMessageAriaPenny}>
								<ChatMessageMetaItem>
									<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
										Penny
									</Text>
								</ChatMessageMetaItem>
							</ChatMessageMeta>
						</ChatMessage>
					</FadeIn>
				</>
				)}
				{((isVideo && ended) || calendlyCompleted || (connectionOption == "Click to call" && voiceStatus == "closed")) && <FadeIn>

					<ChatMessage variant="inbound">
						<ChatBubble>
							{translate("Thanks for connecting with an NFCC member agency.", "Gracias por conectar con una agencia miembro de la NFCC.")}

							{locatorResults.destinationType == "URL" && <> {translate("\n\nYou can get started with this agency at ", "Puede ponerse en contacto con esta agencia en ")} <a href={locatorResults.destination} rel="noreferrer" target="_blank">{locatorResults.destination}</a></>}


						</ChatBubble>
						<ChatMessageMeta aria-label={chatMessageAriaPenny}>
							<ChatMessageMetaItem>
								<Text as="p" color="colorText" lineHeight="lineHeight40" fontSize="fontSize20" fontWeight="fontWeightSemibold">
									Penny
								</Text>
							</ChatMessageMetaItem>
						</ChatMessageMeta>
					</ChatMessage>


				</FadeIn>}
				<div ref={endRef}></div>
			</ChatLog>}
		</CallContainer>
	);
};

export default Locator;
