import { StrictMode } from "react";
import { render } from "react-dom";
import { ConfigProvider } from "./components/ConfigProvider/ConfigProvider";

import { ThemeProvider } from "./components/ThemeProvider/ThemeProvider";
import { VideoProvider } from "./VideoProvider"
import { DemoApp } from "./DemoApp";
import { VoiceProvider } from "./VoiceProvider";

render(
	<StrictMode>
		<ThemeProvider>
			<ConfigProvider>
				<VideoProvider>
					<VoiceProvider>
						<DemoApp />
					</VoiceProvider>
				</VideoProvider>
			</ConfigProvider>
		</ThemeProvider>
	</StrictMode>,
	document.getElementById("root")
);
