import * as React from "react";
import { TwilioVideo, Video } from "@ciptex/race-client-sdk";
import { ReactElementProps } from "./interface";
import useState from "react-usestateref"

export type VideoContextType = {
	video?: Video;
	isMuted: boolean;
	isOpen: boolean;
	ended: boolean;
	attended: boolean;
	init: (accountSid: string, clientName: string, kioskSid: string, service: string, audioOutputDeviceId: string | null) => void;
	connect: () => void;
	disconnect: () => void;
	localVideoMedia: (node: HTMLDivElement) => void;
	remoteVideoMedia: (node: HTMLDivElement) => void;
};

export const VideoContext: React.Context<VideoContextType> = React.createContext<VideoContextType>(null!);

export const VideoProvider: React.FC<ReactElementProps> = ({ children }: ReactElementProps) => {
	const [video, setVideo] = React.useState<Video>();
	const [isOpen, setIsOpen] = React.useState(false);
	const [ended, setEnded] = React.useState(false);
	const [attended, setAttended] = React.useState(false);
	const [isMuted, setIsMuted] = React.useState<boolean>(false);
	const [localMediaContainer, setLocalMediaContainer] = React.useState<HTMLDivElement>();
	const [remoteMediaContainer, setRemoteMediaContainer, remoteMediaContainerRef] = useState<HTMLDivElement>();
	const [kioskSid, setKioskSid] = React.useState<string>();
	const [skill, setSkill] = React.useState<string>();
	const [audioOutputDeviceId, setAudioOutputDeviceId] = React.useState<string>();
	const [rendering, setRendering, renderingRef] = useState(false)
	const [To, setTo, ToRef] = useState<ReturnType<typeof setTimeout> | null>(null)
	const [dim, setDim, dimRef] = useState({ w: 0, h: 0 })

	const resizeObserver = new ResizeObserver((entries) => {

		// console.log('entries', entries)


		if (ToRef.current != null && !rendering) {
			// console.log('clearing timeout')
			// @ts-ignore
			clearTimeout(To);

		}

		const h = document.body.clientHeight;
		const w = document.body.clientWidth;



		if (h === dimRef.current.h && w === dimRef.current.w) {
			// we don't want to resize!
			// console.log('same dims, not resizing')
			return
		}
		else if (!rendering) {
			setDim({ h, w })
			setTo(setTimeout(() => {
				// console.log('rerender from resize', document.body.clientWidth, document.body.clientHeight);
				rerender(true);
			}, 300));
		}

	});


	/*
		//window.addEventListener("visibilitychange", (event) => {console.log('visibility change', event)});
		//var TO :any = false;
		window.addEventListener("resize", function (event) {
			//console.log(document.body.clientWidth + ' wide by ' + document.body.clientHeight+' high');
			//console.log(ToRef.curre
			//console.log('resize',event)
			if (ToRef.current != null && !rendering) {
				//console.log('clearing timeout')
				//@ts-ignore
				clearTimeout(To);

			}

			const h = document.body.clientHeight;
			const w = document.body.clientWidth;

			console.log(dimRef.current.h,h,dimRef.current.w, w)

			if (h === dimRef.current.h && w === dimRef.current.w) {
				//we don't want to resize!
				//console.log('same dims, not resizing')
				return
			}
			else if(!rendering) {
				setDim({ h, w })
				setTo(setTimeout(() => {
					//console.log('rerender from resize', document.body.clientWidth, document.body.clientHeight);
					rerender(true);
				}, 300)); //200 is time in miliseconds
			}

		})
	*/



	const localVideoMedia = React.useCallback((node: HTMLDivElement | null) => {


		if (node && node !== null) {

			console.log("localVideoMedia", node)

			setLocalMediaContainer(node);
		}

	}, []);

	const remoteVideoMedia = React.useCallback((node: HTMLDivElement | null) => {

		if (node && node !== null) {
			setRemoteMediaContainer(node);
		}

	}, []);

	const pauseUnpauseAllRemoteVideo = (node: any, isPause: boolean) => {

		// if isPause, get all the tracks, detach them
		if (isPause) {
			console.log(video, video?.room)
			video?.room.participants.forEach((participant: TwilioVideo.Participant) => {
				console.log(participant)
				const { identity } = participant;
				console.log("identity", identity)
				document.getElementsByName(identity).forEach((node: HTMLElement) => {
					if (node) {
						console.log("node", node)
						/* participant.videoTracks.forEach((videoTrack : TwilioVideo.VideoTrack) => {
							console.log(videoTrack)

							videoTrack.detachTrack(node).remove()
						})*/
						participant.videoTracks.forEach((publication: TwilioVideo.Publication) => {

							const { track } = publication;
							if (track) {
								console.log("track", track)
								track.detach(node);
							}


						});
					}
				})



			})
		}


		else {
			video?.room.participants.forEach((participant: TwilioVideo.Participant) => {
				const { identity } = participant;


				document.getElementsByName(identity).forEach((node: HTMLElement) => {
					if (node) {
						console.log("node", node)
						/* participant.videoTracks.forEach((videoTrack : TwilioVideo.VideoTrack) => {
							console.log(videoTrack)

							videoTrack.detachTrack(node).remove()
						})*/
						participant.videoTracks.forEach((publication: TwilioVideo.Publication) => {

							const { track } = publication;
							if (track) {
								console.log("track", track)
								track.attach(node);
							}


						});
					}
				})

			})
		}


	};

	const rerender = (fromResize: boolean) => {


		if (!fromResize) {
			//
		}
		else if (renderingRef.current) {
			return
		}
		try {
			setRendering(true)
			const node = remoteMediaContainerRef.current

			pauseUnpauseAllRemoteVideo(node, true)



			let width = 0
			let height = 0


			try{
				const m = document.getElementById("callContainer")

				// @ts-ignore
				width = m.clientWidth

				// @ts-ignore
				height = m.clientHeight
				console.log("m", m)


			}
			catch(err)
			{
				const m = document.getElementById("main")

				// @ts-ignore
				width = m.clientWidth

				// @ts-ignore
				height = m.clientHeight
				console.log("m", m)

			}






			const w = 0.9 * width

			if (node && node !== null) {


				let portrait = false;

				if (width < height) {
					portrait = true;
				}


				node.style.width = w.toString() + "px";
				node.style.minWidth = w.toString() + "px";
				node.style.maxWidth = w.toString() + "px";
				setRemoteMediaContainer(node);


				if (portrait) {
					node.style.display = "flex"
					node.style.flexDirection = "column"
					node.style.alignItems = "center"
				}
				else {
					node.style.flexDirection = "row"
					node.style.alignItems = "flex-end"
				}

				let smolBoys = document.getElementById("smolBoys");
				let creatingSmolBoys = false

				if (!smolBoys) {
					creatingSmolBoys = true
					smolBoys = document.createElement("div");
					smolBoys.setAttribute("id", "smolBoys");

				}
				else {
					node.removeChild(smolBoys)
				}




				for (let ii: number = 0; ii < (node.children.length ?? 0); ii++) {
					const child = node.children.item(ii)
					if (node.id != "buttons" && child) {
						if (child.tagName === "AUDIO" && audioOutputDeviceId) {
							try {
								// @ts-ignore
								child.setSinkId(audioOutputDeviceId);
							}
							catch (err) {
								// ignore it lol
								console.log(err)
							}

						}




						if (node.children.length == 2) {
							// @ts-ignore
							if (child.tagName === "VIDEO") {

								// console.log(child, 'setting as single dad')

								if (portrait) {
									const h = height * 0.8
									// @ts-ignore
									child.style = "  margin-right: 10px; min-height: " + h.toString() + "px; height: " + h.toString() + "px; max-height: " + h.toString() + "px; ";
								}
								else {
									// @ts-ignore
									child.style = "width: 100%;  margin-right: 10px;";
								}


								smolBoys.style.width = "0%"
							}

						}
						else {
							// @ts-ignore
							if (child.tagName === "VIDEO") {

								const oldDad = document.getElementById("dad");

								if (oldDad == child || !oldDad) {
									// console.log(child, 'setting as dad')

									if (portrait) {
										const h = height * 0.8
										// @ts-ignore
										child.style = "  margin-right: 10px; min-height: " + h.toString() + "px; height: " + h.toString() + "px; max-height: " + h.toString() + "px; ";
									}
									else {
										// @ts-ignore
										child.style = "width: 85%; minWidth: 85%; maxWidth:85%; margin-right: 3px;";
									}

									// @ts-ignore
									child.id = "dad";

								}
								// @ts-ignore
								else if (child.tagName === "VIDEO" && child.id != "dad") {
									// console.log(child, 'adding to smolboys')
									if (portrait) {

										// @ts-ignore
										child.style = "height: 100px; min-height: 100px; max-height: 100px;   margin-left: 10px; margin-right:0px; margin-top:0px";

									}
									else {
										// @ts-ignore
										child.style = "width: 100%;   margin-right: 10px; margin-top: 10px;";
									}
									// @ts-ignore
									child.id = "video" + ii.toString();


									(child as HTMLElement).onclick = function () { promoteParticipant(child as HTMLElement, portrait) }
									// @ts-ignore
									// smolBoys.appendChild(node.children.item(ii))
									// @ts-ignore
									smolBoys.appendChild(child)
								}



							}

						}

					}

				}

				if (portrait) {
					smolBoys.style.width = w.toString() + "px";
					smolBoys.style.display = "grid";
					smolBoys.style.gridAutoFlow = "column";
					smolBoys.style.justifyContent = "end";
					smolBoys.style.marginLeft = "auto";
				}
				else {
					smolBoys.style.display = "flex"
					smolBoys.style.justifyContent = "flex-end"
					smolBoys.style.flexDirection = "column"
					smolBoys.style.marginRight = "0px"
					// smolBoys.style.marginLeft = '0px'
					smolBoys.style.width = "100%";
				}



				smolBoys.style.width = (portrait && "100%" || "15%")

				if (!document.getElementById("dad") && !creatingSmolBoys) {
					// participant in the big window is gone, promote another
					const newDad = smolBoys.children.item(0)
					if (newDad) {
						if (portrait) {
							const h = document.body.clientHeight * 0.8
							// @ts-ignore
							newDad.style = "  margin-right: 10px; min-height: " + h.toString() + "px; height: " + h.toString() + "px; max-height: " + h.toString() + "px; ";

						}
						else {
							// @ts-ignore
							newDad.style = "width: 85%; margin-right: 3px;";
						}


						newDad.id = "dad";


						node.appendChild(newDad)
					}

				}

				// resize smolboys if there's more than 2 and portrait
				if (smolBoys.children.length >= 2) {

					for (let ii: number = 0; ii < (smolBoys.children.length ?? 0); ii++) {
						// @ts-ignore
						smolBoys.children[ii].style = "height: 70px; min-height: 70px; max-height: 70px;   margin-left: 10px; margin-right:0px; margin-top:0px";
					}
				}
				else {
					for (let ii: number = 0; ii < (smolBoys.children.length ?? 0); ii++) {
						// @ts-ignore
						smolBoys.children[ii].style = "height: 100px; min-height: 100px; max-height: 100px;   margin-left: 10px; margin-right:0px; margin-top:0px";
					}
				}



				if (portrait) {
					// gotta add smolboys before dad
					const dad = document.getElementById("dad")
					if (dad) {
						node.removeChild(dad)
						node.appendChild(smolBoys)
						node.appendChild(dad)
					}


				}
				else {
					// add smolboys after dad
					node.appendChild(smolBoys)
				}



				node.style.display = "flex"
				node.style.justifyContent = "flex-start"

				// if only two participants, hide smolboys
				if (smolBoys.children.length == 0) {
					smolBoys.style.width = "0%"
				}




				setRemoteMediaContainer(node);
				pauseUnpauseAllRemoteVideo(node, false)
				setRendering(false)
				// console.log('RENDERED!')
				/* if (fromResize) {
					rerender(false)
				}*/

			}
		}
		catch (err) {
			alert(err)
		}


	}

	const promoteParticipant = (child: HTMLElement | null, portrait: boolean) => {
		try {

			// console.log('clicked promote', child)
			const smolBoys = document.getElementById("smolBoys");
			const node = remoteMediaContainer
			pauseUnpauseAllRemoteVideo(node, true)

			// console.log('node at start', node)

			if (node && child && smolBoys && child.id != "dad") {
				// @ts-ignore
				node?.removeChild(smolBoys)
				smolBoys?.removeChild(child)

				// find dad and demote
				const oldDad = document.getElementById("dad");
				if (oldDad) {
					// @ts-ignore
					// child.style = oldDad.style;

					// @ts-ignore
					const name = oldDad.getAttribute("name") ?? "video99"
					oldDad.id = name


					if (portrait) {
						const h = document.body.clientHeight * 0.8
						// @ts-ignore
						child.style = "  margin-right: 10px; min-height: " + h.toString() + "px; height: " + h.toString() + "px; max-height: " + h.toString() + "px; ";

					}
					else {
						// @ts-ignore
						child.style = "width: 85%; minWidth: 85%; maxWidth:85%; margin-right: 3px;";
					}

					// @ts-ignore
					if (portrait) {
						// @ts-ignore
						oldDad.style = " margin-left: 10px; margin-right:0px; margin-top:0px; height: 100px; min-height: 100px; max-height: 100px;"
					}
					else {
						// @ts-ignore
						oldDad.style = "width: 100%;   margin-right: 10px; margin-top: 10px;";
					}
					// oldDad.style = "width: 100%; margin-right: 10px; margin-top: 10px;";
					(oldDad as HTMLElement).onclick = function () { promoteParticipant(oldDad, portrait) }


					// console.log('oldDad', oldDad)

					node?.removeChild(oldDad)
					// @ts-ignore
					smolBoys.appendChild(oldDad)



				}


				// @ts-ignore
				// child.style = "width: 85%; margin-right: 3px;";
				child.id = "dad";



				if (portrait) {
					// gotta add smolboys before dad
					node.appendChild(smolBoys)
					node.appendChild(child)



				}
				else {
					// add smolboys after dad
					node.appendChild(child)
					node.appendChild(smolBoys)
				}


				// console.log('promoting', child, 'demoting', oldDad)

				// console.log('node at end', node)

				setRemoteMediaContainer(node);
				pauseUnpauseAllRemoteVideo(node, false)
				console.log("rerender from promote participant")
				rerender(false)
			}
		}
		catch (err) {
			alert(err)
		}

	}



	React.useEffect(() => {

		try {
			resizeObserver.observe(document.getElementById("main") as Element);
		}
		catch (err) {
			console.error(err)
		}

	}, [])

	React.useEffect(() => {
		console.log("localMediaContainer changed", localMediaContainer)
	}, [localMediaContainer])

	React.useEffect(() => {
		console.log("remoteMediaContainer changed", remoteMediaContainer);
	}, [remoteMediaContainer])



	React.useEffect(() => {


		const disabled = () => setIsMuted(true);
		const enabled = () => setIsMuted(false);

		const created = () => {
			console.info("[Client] video#created received", video);
			video?.localTracks?.forEach((track: TwilioVideo.LocalTrack) => {
				track.kind === "audio" && track.on("disabled",
					disabled
				);
				track.kind === "audio" && track.on("enabled", enabled);


			});
		};

		const disconnected = async () => {
			try {
				console.info("[Client] video#disconnected received", "here");
				setTimeout(() => {
					console.log("setting media containers to nothing")
					setLocalMediaContainer(undefined);
					setRemoteMediaContainer(undefined);
					setEnded(true);

				}, 200);
			}
			catch (error) {
				console.log("disc", error)
			}

		}

		if (video && isOpen && localMediaContainer && remoteMediaContainer && kioskSid) {
			video.once("video#created", created);
			video.once("video#disconnected", disconnected);
			video.on("video#trackSubscribed", (payload) => {

				console.log("video#trackSubscribed", payload, "triggering a re-render")


				rerender(false)




			})

			video.on("video#trackUnsubscribed", (payload) => {
				console.log("video#trackUnsubscribed", payload, "triggering a rerender")

				rerender(false)




			})






			video.connect({
				localMediaContainer: localMediaContainer,
				remoteMediaContainer: remoteMediaContainer,
				roomName: kioskSid,
				targetSid: skill

			}).then(() => {
				video.room.options = { ...video.room.options, insights: false }

				video.room.on("participantConnected", (participant: any) => {
					console.log("participant", participant);
					console.log("a counselor has joined from Flex!");
					setAttended(true);



				});

				video.room.on("participantDisconnected", (participant: any) => {
					console.log("participantDisconnected", participant, video.room.participants.size);
					if (video.room.participants.size == 0) {
						disconnect();
					}
				});

				/* video.room.on('trackSwitchedOff', function (track: any, publication : any, participant : any) {
					console.log('trackSwitchedOff', track, publication, participant);

				});

				video.room.on('trackSwitchedOn', function (track: any, publication : any, participant : any) {
					console.log('trackSwitchedOn', track, publication, participant);

				});

				video.room.on('trackSubscriptionFailed', function (error: any, publication : any, participant : any) {
					console.log('trackSubscriptionFailed', error, publication, participant);

				});

				video.room.on('trackSubscribed', function (track: any, publication : any, participant : any) {
					console.log('trackSubscribed', track, publication, participant);

				});

				video.room.on('trackDisabled', function ( publication : any, participant : any) {
					console.log('trackDisabled',  publication, participant);

				});

				video.room.on('trackEnabled', function ( publication : any, participant : any) {
					console.log('trackEnabled',  publication, participant);

				});

				video.room.on('trackUnsubscribed', function ( publication : any, participant : any) {
					console.log('trackUnsubscribed', publication, participant);

				});

				video.room.on('trackSwitchedOn', function (track: any, publication : any, participant : any) {
					console.log('trackSwitchedOn', track, publication, participant);

				});*/


			});


		}

		return () => {
			video?.localTracks?.forEach((track: TwilioVideo.LocalTrack) => {

				track.off("disabled", disabled);
				track.off("enabled", enabled);
			});
		}
	}, [isOpen, video, localMediaContainer, remoteMediaContainer]);

	const init = (accountSid: string, clientName: string, kioskSid: string, service: string, aoDeviceId: string | null): void => {
		try {

			console.log("in init", aoDeviceId)


			const v = new Video({
				accountSid,
				identity: clientName
			});

			setKioskSid(kioskSid)
			setSkill(service)
			if (aoDeviceId) {
				setAudioOutputDeviceId(aoDeviceId)
			}


			v.on("video#ready", () => {
				console.info("[Client] video#ready received");

				console.log("v", v)

				setVideo(v);
			});

		} catch (error) {
			console.error("[Client] ", error);
		}
	}

	const connect = (): void => {
		setIsOpen(true);
	}

	const disconnect = (): void => {
		console.log("Calling disconnect")
		try {
			video?.disconnect();
		}
		catch (error) {
			// alert(error+' - ' + audioOutputDeviceId)
			console.log("disconnect error", error)
		}

	}

	return (<VideoContext.Provider value={{ video, isMuted, isOpen, attended, ended, init, localVideoMedia, remoteVideoMedia, connect, disconnect }}>{children}</VideoContext.Provider>);
}