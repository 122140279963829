import { Context, createContext, FC, useState } from "react";
import { Voice } from "@ciptex/race-client-sdk";
import { ReactElementProps } from "./interface";

export type VoiceContextType = {
	voice?: Voice;
	status: Voice.State;
	isMuted: boolean;

	init: (identity: string) => void;
	connect: (v: Voice, identity: string) => void;
	//connect: (to :string) => void;
	disconnect: () => void;
};

export const VoiceContext: Context<VoiceContextType> = createContext<VoiceContextType>(null!);

export const VoiceProvider: FC<ReactElementProps> = ({ children }: ReactElementProps) => {
	const [voice, setVoice] = useState<Voice>();
	const [status, setStatus] = useState<Voice.State>("pending");
	const [isMuted, setIsMuted] = useState<boolean>(false);
	//const connect = async (to :string) : Promise<void> => {

	const connect = async (v: Voice, identity: string): Promise<void> => {
		console.log('in voice connect', v)


		let call = await v.connect({ to: "+441670334396", params: {} });
		//console.log('call', call)

		/*const call: Promise<Voice.Call> = await v.client.connect({
			params: {
				To: "+441670334396",
				From: identity
			}
		});*/

		console.log('call', call)


		v.client.on('error', (twilioError: any, call: Voice.Call) => {
			console.log('An error has occurred: ', twilioError);
		});
	}

	const init = (identity: string): void => {
		try {
			console.log('in voice init', identity)


			const v = new Voice({
				accountSid: 'AC3f74fe2c46e582ab786e22e3dab2c4f2', //ACCOUNT_SID,
				identity, //IDENTITY,
				voiceAppSid: 'APd500a4607105969de87a122b40ec8fe0' //VOICE_APP_SID
			});

			console.log('v', v)

			v.on("voice#ready", () => {
				console.info("[Client] voice#ready received");
				setVoice(v);
				connect(v, identity);
			});

			v.on("voice#status", (state: Voice.State) => {
				console.info("[Client] voice#status received ", state);
				setStatus(state);
			});

			v.on("voice#mute", (is: boolean) => {
				console.info("[Client] voice#mute received ", is);
				setIsMuted(is);
			})
		} catch (error) {
			console.error("[Client] ", error);
		}
	}



	const disconnect = (): void => {
		voice?.disconnect();
	}

	return (<VoiceContext.Provider value={{ init, connect, disconnect, voice, status, isMuted }}>{children}</VoiceContext.Provider>);
}